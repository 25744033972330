










import { Component, Vue } from 'vue-property-decorator';
import Lottie from '@/components/commons/Lottie.vue';
import Button from '@/components/commons/Button.vue';

@Component({ components: { lottie: Lottie, Button } })
export default class NotFound extends Vue {}
